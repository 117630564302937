import React from "react";

import {
  Button,
  Typography,
  TypographyPropsVariantOverrides,
  ButtonProps,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import { OverridableStringUnion } from "@material-ui/types";
import { Variant } from "@material-ui/core/styles/createTypography";
import MainButtonBackground from "@public/main-button-background.svg";
import cx from "classnames";

interface MainButtonProps extends Omit<ButtonProps, "variant"> {
  title: string;
  startIcon?: React.ReactNode;
  variantText?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  buttonPadding?: string;
  fullWidth?: boolean;
  onClick?: () => void;
  variant?: "primary" | "secondary" | "outlined";
}

const useStyles = makeStyles((theme: ITheme, props) => ({
  button: {
    borderRadius: 8,
    textTransform: "capitalize",
    padding: props.buttonPadding ? props.buttonPadding : "8px 18px 8px 18px",
    position: "relative",
    "&.Mui-disabled": {
      opacity: "0.3 !important",
    },
  },
  primary: {
    color: theme.palette.button.primary.text,
    background: theme.palette.button.primary.background,
    border: `1px solid ${theme.palette.button.primary.border}`,
    "&:hover": {
      background: theme.palette.button.primary.backgroundHover,
    },
    "&.Mui-disabled": {
      color: theme.palette.button.primary.text,
    },
  },
  secondary: {
    color: theme.palette.button.secondary.text,
    backdropFilter: "blur(16px)",
    background: theme.palette.button.secondary.background,
    border: `1px solid ${theme.palette.button.secondary.border}`,
    "&:hover": {
      background: theme.palette.button.secondary.backgroundHover,
    },
    "&.Mui-disabled": {
      color: theme.palette.button.secondary.text,
    },
  },
  outlined: {
    backdropFilter: "blur(16px)",
    color: theme.palette.button.outlined.text,
    background: theme.palette.button.outlined.background,
    border: `1px solid ${theme.palette.button.outlined.border}`,
    "&:hover": {
      background: theme.palette.button.outlined.backgroundHover,
    },
    "&.Mui-disabled": {
      color: theme.palette.button.outlined.text,
    },
  },
  buttonIcon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

const MainButton = ({
  title,
  startIcon,
  variantText = "body1",
  buttonPadding,
  fullWidth = false,
  onClick,
  variant = "primary",
  ...props
}: MainButtonProps) => {
  const classes = useStyles({
    buttonPadding,
  });

  return (
    <Button
      {...props}
      fullWidth={fullWidth}
      className={cx(classes.button, classes[variant])}
      startIcon={startIcon}
      onClick={onClick}>
      <Typography variant={variantText} color="inherit">
        {title}
      </Typography>
      {variant === "primary" ? (
        <MainButtonBackground className={classes.buttonIcon} />
      ) : null}
    </Button>
  );
};

export default MainButton;
